<template>
  <div :class="['v-timeline-item', { 'v-timeline-item--diff': diff, 'v-timeline-item--no-text': !diff }]">
    <div v-if="diff" class="v-timeline-item__diff">
      <div class="v-timeline-item__diff-delimeter"></div>
      <div class="v-timeline-item__diff-delimeter"></div>
      <div class="v-timeline-item__diff-text">{{ diff }}</div>
      <div class="v-timeline-item__diff-delimeter"></div>
      <div class="v-timeline-item__diff-delimeter"></div>
    </div>
    <div v-else class="v-timeline-item__diff">
      <div class="v-timeline-item__diff-delimeter"></div>
      <div class="v-timeline-item__diff-delimeter"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VTimelineItem',
  props: {
    diff: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.v-timeline-item--no-text {
  margin-top: 0; /* Убираем отступ для случая без текста */
}
.v-timeline__items > .v-timeline-item--no-text:first-child > .v-timeline-item__diff {
  display: none;
}
</style>

<template>
  <match-media v-slot="{ mobile }">
    <div class="owner-edit">
      <div class="owner-edit__header">
        <v-breadcrumbs :items="$options.OWNER_EDIT_BREADCRUMBS" class="owner-edit__breadcrumbs" />
        <v-page-title class="owner-edit__title">{{ ownerData.name }}</v-page-title>
        <div v-if="ownerData.lastLogin" class="owner-edit__last-activity">
          Был активен {{ formatDate(ownerData.lastLogin, '.') }}
        </div>
        <div class="owner-edit__tags-block">
          <v-tag-group :value="!!ownerData.agreementDocumentCount" :group="$options.DOCUMENTS_STATUSES_GROUP">
            <template #default="{ color, text }">
              <v-tag :color="color"> {{ text }}</v-tag>
            </template>
          </v-tag-group>
          <v-tag
            v-if="ownerData.isEulaAccepted && ownerData.dateJoined"
            color="dark-gray"
            class="owner-edit__offer-agreement"
            >Согласие с офертой от {{ formatDate(ownerData.dateJoined, '.') }}</v-tag
          >
        </div>
      </div>
      <v-popover-actions v-if="mobile" class="owner-edit__add-history-popover">
        <v-button class="owner-edit__add-history-button" primary>Добавить запись</v-button>
        <template #actions="{ onClick }">
          <v-button-wide @click="onClick(openMobileHistoryForm($options.COMMENT))">Комментарий</v-button-wide>
          <v-button-wide @click="onClick(openMobileHistoryForm($options.OUTGOING_CALL))">Исходящий лид</v-button-wide>
        </template>
      </v-popover-actions>
      <div class="owner-edit__content">
        <!-- sections -->
        <div v-if="!loading" class="owner-edit__sections-wrapper">
          <v-tabs v-if="mobile" v-model="mobileTab" class="interaction-history-form__tabs" primary>
            <v-tab v-for="(tab, index) of $options.OWNER_EDIT_MOBILE_TABS_MAP" :key="index" :name="tab.value">
              {{ tab.label }}
            </v-tab>
          </v-tabs>
          <div
            v-show="!mobile || mobileTab === $options.OWNER_EDIT_MOBILE_TABS.INFORMATION"
            class="owner-edit__information-block"
          >
            <owners-edit-about
              class="owner-edit__section"
              v-bind="ownerData"
              :changes="ownerDraftChanges"
              @edit="showEditDialog"
            ></owners-edit-about>
            <owners-edit-documents
              v-for="(category, index) in $options.DOCUMENTS_CATEGORIES"
              :key="index"
              :files="getFilesByCategory(`${category.name}`)"
              :category="category.name"
              :title="category.title"
              class="owner-edit__section"
              @add-files="addDocument"
              @removeFile="removeDocument"
            ></owners-edit-documents>
            <owners-edit-objects
              class="owner-edit__section"
              :owner-objects="ownerData.adverts"
              :pending-objects="pendingObjects"
              :all-objects="allOwnerObjectsList"
              @delete-owner-object="deleteOwnerObject"
              @add-owner-object="addOwnerObject"
              @search-objects="searchObjects"
            ></owners-edit-objects>
          </div>
          <div
            v-show="!mobile || mobileTab === $options.OWNER_EDIT_MOBILE_TABS.HISTORY"
            class="owner-edit__history-block"
          >
            <v-interaction-history
              ref="ownerHistory"
              class="owner-edit__section"
              entity="owner"
            ></v-interaction-history>
          </div>
        </div>
      </div>
      <owner-edit-dialog :visible.sync="editDialogVisible" :owner-data="ownerData" @submitted="editOwnerSubmit" />
      <owner-edit-pending-dialog
        :visible.sync="editPendingDialogVisible"
        :draft-data="ownerDraftData"
        :owner-data="ownerData"
        @submitted="editOwnerSubmit"
      />
    </div>
  </match-media>
</template>

<script>
import { SHOW, OUTGOING_CALL, COMMENT } from '@/constants/ownerInteractionHistory'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { advertsService, ownersService } from '@/services/http'
import redirectIfNetworkIssue from '@/router/utils'
import { loadingService } from '@/services/loading'
import { MatchMedia } from 'vue-component-media-queries'
import VBreadcrumbs from '@/components/common/VBreadcrumbs.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VTag from '@/components/common/VTag.vue'
import VTagGroup from '@/components/common/VTagGroup.vue'
import { formatDate } from '@/utils/formatters'
import OwnersEditAbout from '@/components/Owner/Edit/About.vue'
import OwnersEditObjects from '@/components/Owner/Edit/Objects.vue'
import { DOCUMENTS_STATUSES_GROUP } from '@/constants/documentsStatuses'
import OwnersEditDocuments from '@/components/Owner/Edit/Documents.vue'
import { DOCUMENTS_CATEGORIES } from '@/constants/documentsCategories'
import OwnerEditDialog from '@/components/Owner/EditDialog.vue'
import OwnerEditPendingDialog from '@/components/Owner/EditPendingDialog.vue'
import VInteractionHistory from '@/components/common/InteractionHistory/VInteractionHistory.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButton from '@/components/common/VButton.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'

const OWNER_EDIT_MOBILE_TABS = {
  INFORMATION: 'information',
  HISTORY: 'history'
}
const OWNER_EDIT_MOBILE_TABS_MAP = [
  { label: 'История', value: OWNER_EDIT_MOBILE_TABS.HISTORY },
  { label: 'Информация', value: OWNER_EDIT_MOBILE_TABS.INFORMATION }
]

const OWNER_EDIT_BREADCRUMBS = [
  {
    text: 'Собственники',
    to: { name: 'owners' }
  },
  {
    text: 'Страница собственника',
    disabled: true
  }
]

export default {
  SHOW,
  OUTGOING_CALL,
  COMMENT,
  DOCUMENTS_CATEGORIES,
  DOCUMENTS_STATUSES_GROUP,
  OWNER_EDIT_BREADCRUMBS,
  OWNER_EDIT_MOBILE_TABS,
  OWNER_EDIT_MOBILE_TABS_MAP,
  name: 'OwnerEdit',
  components: {
    VButtonWide,
    VButton,
    VPopoverActions,
    VTab,
    VTabs,
    VInteractionHistory,
    OwnerEditPendingDialog,
    OwnerEditDialog,
    OwnersEditDocuments,
    OwnersEditObjects,
    OwnersEditAbout,
    VTagGroup,
    VTag,
    MatchMedia,
    VPageTitle,
    VBreadcrumbs
  },
  data() {
    return {
      editDialogVisible: false,
      editPendingDialogVisible: false,
      ownerData: {},
      ownerDraftData: {},
      loading: false,
      allOwnerObjectsList: [],
      ownerStatuses: [],
      ownerCallSources: [],
      ownerDraftChanges: {},
      mobileTab: OWNER_EDIT_MOBILE_TABS.HISTORY
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    ownerId() {
      return this.$route.params.ownerId
    },
    pendingObjects() {
      const advertOld = this.ownerDraftChanges?.ownerProfile?.adverts?.oldValue || []
      const advertNew = this.ownerDraftChanges?.ownerProfile?.adverts?.newValue || []
      const oldIds = advertOld.map(advert => advert.id)
      const newIds = advertNew.map(advert => advert.id)
      const addedObjects = advertNew.filter(advert => !oldIds.includes(advert.id))
      const deletedObjects = advertOld.filter(advert => !newIds.includes(advert.id))
      return { added: addedObjects, deleted: deletedObjects }
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  created() {
    // TODO: переделать на allSettled
    // Придумать обработку ошибок
    this.loading = true
    const OwnerCallSourcesRequest = this.fetchOwnerCallSources()
    const OwnerStatusesRequest = this.fetchOwnerStatuses()
    const AllObjectsRequest = this.searchObjects()

    Promise.all([OwnerCallSourcesRequest, OwnerStatusesRequest, AllObjectsRequest])
      .then(() => {
        this.fetchOwnerById(this.ownerId)
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    openMobileHistoryForm(type) {
      return this.$refs.ownerHistory.openMobileHistoryForm(type)
    },
    formattingOwnerData(data) {
      return {
        ...data,
        callSource: this.getOwnerCallSource(data),
        status: this.getOwnerStatus(data),
        agent: data.ownerProfile.agent,
        adverts: data.ownerProfile.adverts
      }
    },
    getOwnerCallSource({ ownerProfile }) {
      if (this.ownerCallSources.length) {
        const { callSource: currentCallSources } = ownerProfile
        if (currentCallSources) {
          const source = this.ownerCallSources.find(
            callSource => callSource.id === (currentCallSources.id || currentCallSources)
          )
          if (source) return source
        }
      }
      return 'Не выбран'
    },
    getOwnerStatus({ ownerProfile }) {
      if (this.ownerStatuses.length) {
        const { status: currentStatus } = ownerProfile
        if (currentStatus) {
          const statusObject = this.ownerStatuses.find(status => status.id === (currentStatus.id || currentStatus))
          if (statusObject) return statusObject
        }
      }
      return ''
    },
    editOwnerSubmit(newOwnerData) {
      this.ownerData = this.formattingOwnerData(newOwnerData)
      this.ownerDraftChanges = {}
      this.fetchOwnerEditChanges(newOwnerData)
    },
    showEditDialog() {
      if (this.ownerData.hasPending) this.editPendingDialogVisible = true
      else this.editDialogVisible = true
    },
    formatDate,
    getFilesByCategory(category) {
      const pendingFiles =
        this.ownerDraftData?.filesAdd?.map(addedFile => {
          return { ...addedFile, hasPending: true }
        }) || []
      const ownerFiles =
        this.ownerData?.files?.map(file => {
          const fileIsDeleted = !!this.ownerDraftData?.filesDelete?.find(deletedFile => deletedFile.id === file.id)
          if (fileIsDeleted) {
            return { ...file, hasDeletePending: true }
          }
          return file
        }) || []
      const allFiles = [...ownerFiles, ...pendingFiles]
      return allFiles?.filter(file => file.category === category)
    },
    searchObjects(searchrow) {
      return advertsService.select(searchrow).then(resultList => {
        this.allOwnerObjectsList = resultList
      })
    },
    addDocument({ ids, category }) {
      const files = ids.map(id => {
        return { category, backgroundFileId: id }
      })
      this.updateOwner({ files })
    },
    removeDocument(id) {
      const params = { deletedFiles: [id] }
      this.updateOwner(params)
    },
    updateOwner(params) {
      return ownersService
        .update(this.ownerData.id, params, false, params.addedObject ? { adverts: params.addedObject } : undefined)
        .then(newOwnerData => {
          this.ownerData = this.formattingOwnerData(newOwnerData)
          this.fetchOwnerEditChanges(newOwnerData)
        })
    },
    addOwnerObject(newObjectId) {
      const newOwnerObjectsList = [
        ...this.ownerData.adverts.map(advert => advert.id),
        ...this.pendingObjects.added.map(obj => obj.id),
        ...this.pendingObjects.deleted.map(obj => obj.id),
        newObjectId
      ]
      const filteredObjectsList = newOwnerObjectsList.filter(
        elem => ![...this.pendingObjects.deleted.map(obj => obj.id)].includes(elem)
      )
      const params = { ownerProfile: { adverts: filteredObjectsList }, addedObject: 1 }
      this.updateOwner(params)
    },
    deleteOwnerObject(deletedObjectId) {
      const filteredObjectsList = this.ownerData.adverts
        .map(advert => advert.id)
        .filter(elem => ![...this.pendingObjects.deleted.map(obj => obj.id), deletedObjectId].includes(elem))
      const params = {
        ownerProfile: {
          adverts: [...filteredObjectsList, ...this.pendingObjects.added.map(obj => obj.id)]
        }
      }
      this.updateOwner(params)
    },
    goBack() {
      this.$router.push({ name: 'owners' })
    },
    fetchOwnerEditChanges({ hasPending }) {
      if (hasPending) {
        this.loading = true
        Promise.all([this.fetchOwnerDraft(), this.fetchOwnerDraftChanges()]).finally(() => {
          this.loading = false
        })
      }
    },
    fetchOwnerDraft() {
      return ownersService.getOwnerDraft(this.ownerData.versionId).then(draftData => {
        this.ownerDraftData = this.formattingOwnerData(draftData)
      })
    },
    fetchOwnerDraftChanges() {
      return ownersService.getOwnerDraftChanges(this.ownerData.versionId).then(({ content }) => {
        this.ownerDraftChanges = content
      })
    },
    fetchOwnerById(id) {
      return ownersService
        .getOwnerById(id)
        .then(ownerData => {
          this.ownerData = this.formattingOwnerData(ownerData)
          this.fetchOwnerEditChanges(ownerData)
        })
        .catch(redirectIfNetworkIssue)
    },
    fetchOwnerStatuses() {
      return ownersService.getOwnerStatusesMap().then(statuses => {
        this.ownerStatuses = statuses
      })
    },
    fetchOwnerCallSources() {
      return ownersService.getCallSourcesMap().then(callSources => {
        this.ownerCallSources = callSources
      })
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['interaction-history-list-item', _vm.itemTypeClass]},[(_vm.interaction.type === 'change' || _vm.isHistoryDeletion)?_c('v-interaction-history-list-item-type-change',{attrs:{"interaction-author":_vm.interaction.author,"interaction-data":_vm.interaction.data,"history-delete-type":_vm.isHistoryDeletion ? _vm.interaction.type : ''}}):(_vm.interaction.type === 'customer_registration')?[_c('div',[_vm._v(_vm._s(_vm.interaction.data.text)+": "+_vm._s(_vm.interaction.historyDate))])]:[(_vm.interaction.hasPending)?_c('v-overlay',[_vm._v("Изменения вступят в силу после проверки.")]):_vm._e(),(_vm.showCheckBox)?_c('v-reminder-checkbox',{staticClass:"interaction-history-list-item__checkbox",attrs:{"expired":_vm.isEventExpired},model:{value:(_vm.isCompleted),callback:function ($$v) {_vm.isCompleted=$$v},expression:"isCompleted"}}):_vm._e(),_c('div',{staticClass:"interaction-history-list-item__date"},[(_vm.hasReminderDatetime)?[_vm._v(_vm._s(_vm.interaction.reminderDatetime.slice(0, 10)))]:[_vm._v(_vm._s(_vm.interaction.historyDate)+" "+_vm._s(_vm.interaction.historyTime))]],2),_c('v-avatar',{staticClass:"interaction-history-list-item__author-photo",attrs:{"size":"small","src":_vm.interaction.author.photo}}),_c('div',{staticClass:"interaction-history-list-item__author-name"},[_vm._v(_vm._s(_vm.interaction.author.name))]),_c('div',{staticClass:"interaction-history-list-item__tags"},[(_vm.statusTag)?_c('v-tag-group',{staticClass:"interaction-history-list-item__type",attrs:{"value":_vm.statusTag.value,"group":_vm.statusTag.group},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('v-tag',{staticClass:"interaction-history-list-item__type-tag",attrs:{"color":color}},[_vm._v(" "+_vm._s(text))])]}}],null,false,2155972636)}):_vm._e(),_c('v-tag-group',{staticClass:"interaction-history-list-item__type",attrs:{"value":_vm.interaction.type,"group":_vm.$options.INTERACTION_HISTORY_TYPES_GROUP},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('v-tag',{staticClass:"interaction-history-list-item__type-tag",attrs:{"color":color}},[_vm._v(" "+_vm._s(text))])]}}])})],1),(_vm.showContextMenu)?_c('v-popover-actions',{staticClass:"interaction-history-list-item__menu",attrs:{"placement":"bottom-end"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var onClick = ref.onClick;
return [_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.edit)}}},[_vm._v("Редактировать")]),(_vm.canDeleteItem)?_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.remove)}}},[_vm._v("Удалить")]):_vm._e()]}}],null,false,3079316477)},[_c('v-button-icon',[_c('v-icon-dots')],1)],1):_vm._e(),_c('div',{staticClass:"interaction-history-list-item__text",domProps:{"innerHTML":_vm._s(_vm.getTextWithLinks(_vm.interaction.data.text))}}),(_vm.interactionFiles.length)?_c('div',{staticClass:"interaction-history-list-item__files"},[_c('v-interaction-history-file-list',{attrs:{"hide-delete-button":"","permanent-files":_vm.interactionFiles}})],1):_vm._e(),(_vm.hasAdvert)?_c('objects-realty-list-item',_vm._b({staticClass:"interaction-history-list-item__advert",attrs:{"hide-actions":"","compact-view":""}},'objects-realty-list-item',_vm.formatObjectRealty(_vm.interaction.data.advert),false)):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
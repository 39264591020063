<template>
  <div :class="['interaction-history-list-item', itemTypeClass]">
    <v-interaction-history-list-item-type-change
      v-if="interaction.type === 'change' || isHistoryDeletion"
      :interaction-author="interaction.author"
      :interaction-data="interaction.data"
      :history-delete-type="isHistoryDeletion ? interaction.type : ''"
    />
    <template v-else-if="interaction.type === 'customer_registration'">
      <div>{{ interaction.data.text }}: {{ interaction.historyDate }}</div>
    </template>

    <template v-else>
      <v-overlay v-if="interaction.hasPending">Изменения вступят в силу после проверки.</v-overlay>
      <v-reminder-checkbox
        v-if="showCheckBox"
        v-model="isCompleted"
        class="interaction-history-list-item__checkbox"
        :expired="isEventExpired"
      ></v-reminder-checkbox>
      <div class="interaction-history-list-item__date">
        <template v-if="hasReminderDatetime">{{ interaction.reminderDatetime.slice(0, 10) }}</template
        ><template v-else>{{ interaction.historyDate }} {{ interaction.historyTime }}</template>
      </div>
      <v-avatar
        class="interaction-history-list-item__author-photo"
        size="small"
        :src="interaction.author.photo"
      ></v-avatar>
      <div class="interaction-history-list-item__author-name">{{ interaction.author.name }}</div>
      <div class="interaction-history-list-item__tags">
        <v-tag-group
          v-if="statusTag"
          class="interaction-history-list-item__type"
          :value="statusTag.value"
          :group="statusTag.group"
        >
          <template #default="{ color, text }">
            <v-tag class="interaction-history-list-item__type-tag" :color="color"> {{ text }}</v-tag>
          </template>
        </v-tag-group>
        <v-tag-group
          class="interaction-history-list-item__type"
          :value="interaction.type"
          :group="$options.INTERACTION_HISTORY_TYPES_GROUP"
        >
          <template #default="{ color, text }">
            <v-tag class="interaction-history-list-item__type-tag" :color="color"> {{ text }}</v-tag>
          </template>
        </v-tag-group>
      </div>
      <v-popover-actions v-if="showContextMenu" placement="bottom-end" class="interaction-history-list-item__menu">
        <v-button-icon><v-icon-dots /> </v-button-icon>
        <template #actions="{ onClick }">
          <v-button-wide @click="onClick(edit)">Редактировать</v-button-wide>
          <v-button-wide v-if="canDeleteItem" @click="onClick(remove)">Удалить</v-button-wide>
        </template>
      </v-popover-actions>
      <div class="interaction-history-list-item__text" v-html="getTextWithLinks(interaction.data.text)"></div>
      <div v-if="interactionFiles.length" class="interaction-history-list-item__files">
        <v-interaction-history-file-list
          hide-delete-button
          :permanent-files="interactionFiles"
        ></v-interaction-history-file-list>
      </div>
      <objects-realty-list-item
        v-if="hasAdvert"
        class="interaction-history-list-item__advert"
        v-bind="formatObjectRealty(interaction.data.advert)"
        hide-actions
        compact-view
      />
    </template>
  </div>
</template>

<script>
import VTag from '@/components/common/VTag.vue'
import VTagGroup from '@/components/common/VTagGroup.vue'
import VAvatar from '@/components/common/VAvatar.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import ObjectsRealtyListItem from '@/components/ObjectsRealty/ListItem.vue'
import {
  INTERACTION_HISTORY_TYPES_FORM,
  INTERACTION_HISTORY_TYPES_GROUP,
  INTERACTION_HISTORY_DELETE_TYPES,
  INCOMING_CALL,
  FILE,
  SHOW,
  CHANGE,
  COMMENT,
  OUTGOING_CALL,
  FAVORITE
} from '@/constants/ownerInteractionHistory'
// eslint-disable-next-line vue/max-len
import VInteractionHistoryListItemTypeChange from '@/components/common/InteractionHistory/VInteractionHistoryListItemTypeChange.vue'
import VOverlay from '@/components/common/VOverlay.vue'
import { formatObjectRealty, reformatDate, getTextWithLinks } from '@/utils/formatters'
import { mapGetters, mapState } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { isBefore, isSameDay, startOfToday } from 'date-fns'
import VInteractionHistoryFileList from '@/components/common/InteractionHistory/VInteractionHistoryFileList.vue'
import { SHOWS_STATUSES_GROUP } from '@/constants/showsStatuses'
import { CALLS_STATUSES_GROUP } from '@/constants/callsStatuses'
import VReminderCheckbox from '@/components/common/VReminderCheckbox.vue'

export default {
  SHOWS_STATUSES_GROUP,
  CALLS_STATUSES_GROUP,
  INTERACTION_HISTORY_TYPES_FORM,
  INTERACTION_HISTORY_TYPES_GROUP,

  name: 'VInteractionHistoryListItem',
  components: {
    VReminderCheckbox,
    VInteractionHistoryFileList,
    VOverlay,
    VTag,
    VTagGroup,
    VAvatar,
    VButtonIcon,
    VIconDots,
    VPopoverActions,
    VButtonWide,
    ObjectsRealtyListItem,
    VInteractionHistoryListItemTypeChange
  },
  props: {
    interaction: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      localCheck: false,
      currentTime: new Date()
    }
  },
  inject: ['setEventAsCompleted'],
  computed: {
    isEventExpired() {
      return isBefore(new Date(reformatDate(this.interaction.historyDate)), startOfToday()) && !this.isCompleted
    },
    isCompleted: {
      get() {
        return this.localCheck
      },
      set(newValue) {
        this.setEventAsCompleted({ isCompleted: newValue, id: this.eventReminderId })
        this.localCheck = newValue
      }
    },
    ...mapState(MODULE_SESSION, {
      currentUserId: state => state.model.id
    }),
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    showContextMenu() {
      return [INCOMING_CALL, SHOW].includes(this.interaction?.type)
        ? this.isRoleAdmin || this.isCurrentAgentHistoryAuthor
        : true
    },
    isCurrentAgentHistoryAuthor() {
      return this.interaction?.author?.id === this.currentUserId
    },
    canDeleteItem() {
      return this.interaction.type === INCOMING_CALL ? this.isRoleAdmin : true
    },
    statusTag() {
      const interactionType = this.interaction.type
      if ([SHOW, INCOMING_CALL].includes(interactionType)) {
        const { type, showStatus } = this.interaction?.data
        return interactionType === SHOW
          ? { value: showStatus, group: SHOWS_STATUSES_GROUP }
          : { value: type, group: CALLS_STATUSES_GROUP }
      }
      return null
    },
    showCheckBox() {
      if (!this.interaction.reminderDatetime) return false
      const formattedReminderDate = new Date(reformatDate(this.interaction.reminderDatetime.split(' ')[0]))
      return (
        [OUTGOING_CALL, SHOW].includes(this.interaction?.type) &&
        (isSameDay(formattedReminderDate, this.currentTime) || isBefore(formattedReminderDate, this.currentTime))
      )
    },
    itemTypeClass() {
      const prefix = 'interaction-history-list-item'
      let itemTypeClass = ''
      if (this.interaction.type === COMMENT) itemTypeClass = `${prefix}--comment`
      if (this.interaction.type === FILE) itemTypeClass = `${prefix}--file`
      if (this.interaction.type === INCOMING_CALL) itemTypeClass = `${prefix}--incoming-call`
      if (this.interaction.type === OUTGOING_CALL) itemTypeClass = `${prefix}--outgoing-call`
      if (this.interaction.type === SHOW) itemTypeClass = `${prefix}--show`
      if (this.interaction.type === CHANGE) itemTypeClass = `${prefix}--change`
      if (this.interaction.type === FAVORITE) itemTypeClass = `${prefix}--customer_favorite`
      return itemTypeClass
    },
    hasReminderDatetime() {
      return (
        this.$options.INTERACTION_HISTORY_TYPES_FORM.includes(this.interaction.type) &&
        this.interaction.reminderDatetime
      )
    },
    interactionFiles() {
      return this.interaction?.files || this.interaction?.data?.photos || []
    },
    hasAdvert() {
      return Boolean(this.interaction.data.advert)
    },
    isHistoryDeletion() {
      return Object.keys(INTERACTION_HISTORY_DELETE_TYPES).includes(this.interaction.type)
    },
    isEventCompleted() {
      return this.interaction?.reminder?.isCompleted
    },
    eventReminderId() {
      return this.interaction?.reminder?.id
    }
  },
  watch: {
    isEventCompleted: {
      immediate: true,
      handler(val) {
        this.localCheck = val
      }
    }
  },
  methods: {
    formatObjectRealty,
    edit() {
      this.$emit('edit')
    },
    remove() {
      this.$emit('remove')
    },
    getTextWithLinks
  }
}
</script>
